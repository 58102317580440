.main {
    padding-top: 50px;
}

.tableWrapper {
    background: var(--white);
    box-shadow: 4px 4px 18px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    padding: 0 20px 40px 20px;
    max-height: 55vh;
}

.table {
    width: 100%;
    text-align: center;
}

.checkbox {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    cursor: pointer;
}

.tbody {
    display: block;
    max-height: calc(55vh - 80px);
    overflow-y: auto;
}

.trHead {
    border-bottom: 1px solid var(--secondary);
}

.tr {
    cursor: pointer;
    transition: all 0.3s ease-out 0.1s;
}

.tr:nth-child(even) {
    background-color: var(--stroke);
}

.tr:hover {
    background-color: rgba(0, 173, 210, 0.5);
}

.thFirst,
.tdFirst,
.thLast {
    text-align: left;
}

.th,
.thFirst,
.thLast {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--secondary);
    padding: 40px 5px 20px 0;
    cursor: pointer;
}

.thFirst,
.tdFirst {
    padding-left: 20px;
}

.td,
.tdLast {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--black);
    padding: 16px 0;
}

.th5 {
    width: 19vw;
    padding-left: 5px;
}

.td1 {
    width: 16vw;
}

.td2 {
    width: 7vw;
}

.td3 {
    width: 11vw;
}

.td4 {
    width: 10vw;
}

.td5 {
    width: 19vw;
}

.tdLast {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 24px;
}

.btnsWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.flex {
    display: flex;
}

.create {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid transparent;
    border-radius: 30px;
    height: 40px;
    background: var(--secondary);
    color: var(--white);
    cursor: pointer;
    transition: all 0.3s ease-out 0.1s;
    width: 128px;
    margin-right: 30px;
}

.create:hover {
    background: var(--white);
    color: var(--secondary);
    border: 1px solid var(--secondary);
}

.btnContainer {   
    display: inline;
    margin-right: 20px;
}

.compliantContainer {
    display: flex;
    align-items: center;
}

.img {
    margin-right: 10px;
}

.compliant,
.notCompliant {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
}

.compliant{
    color: var(--green);
}

.notCompliant {
    color: var(--error);
}

.icon {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 2px;
}