.header,
.nav {
    display: flex;
    justify-content: space-between;
}

.header {
    padding-top: 140px;
}

.nav {
    width: 170px;
}

.navLink,
.active {
    color: var(--gray);
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    padding-bottom: 10px;
    border-bottom: 1px solid transparent;
    transition: all 0.3s ease-out 0.1s;
}

.navLink:hover {
    color: var(--primary);
}

.active {
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
}

.hi {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: var(--gray);
    margin-right: 30px;
}

