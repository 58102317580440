.inputDate {
    width: 120px;
    color: var(--gray); 
    margin-right: 16px;
}

.flex1 {
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
}

.flex2 {
    display: flex;
    align-items: center;
    margin-right: auto;
    margin-bottom: 20px;
}