.container {
    display: inline-block;
    position: relative;
}

.placeholder {
    position: absolute;
    top: -7px;
    left: 12px;
    padding: 0 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--secondary);
    background: var(--white);
}

.input {
    width: 100%;
    height: 40px;
    border: 1px solid var(--stroke);
    border-radius: 10px;
    outline: none;
    text-indent: 12px;
    color: var(--black);
  }
  
.input::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--gray);
    text-indent: 12px;
}

.xsm {
    width: 100px;
    color: var(--secondary);
}

.x25 {
    width: calc(25% - 22.5px);
}

.x33 {
    width: calc(33.3% - 20px);
}

.x50 {
    width: calc(50% - 15px);
}

.x100 {
    width: 100%;
}

.required {
    border: 1px solid var(--error);
}