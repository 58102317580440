.button {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border: 1px solid transparent;
    border-radius: 30px;
    padding: 8px 40px;
    cursor: pointer;
    transition: all 0.3s ease-out 0.1s;
}

.accent,
.pdf {
    font-size: 14px;
    line-height: 18px;
    border-radius: 15px;
    padding: 4px 15px;
    background: var(--accent);
    color: var(--white);
}

.disabled,
.disabled2 {
    opacity: 0.5;
    cursor: unset;
}

.bigAccent {
    background: var(--accent);
    color: var(--white);
}

.accent:hover,
.bigAccent:hover {
    color: var(--accent);
    background: var(--white);
    border: 1px solid var(--accent);
}

.primary,
.primaryBig,
.disabled2 {
    background: var(--primary);
    color: var(--white);
}

.primaryBig {
    display: block;
    margin: 0 auto;
    margin-bottom: 60px;
    font-size: 24px;
    line-height: 30px;
    padding: 16px 150px;
}

.primary:hover,
.primaryBig:hover {
    background: var(--white);
    color: var(--primary);
    border: 1px solid var(--primary);
}

.secondary,
.pdf,
.disabled {
    background: var(--secondary);
    color: var(--white);
}

.pdf {
    background-image: url(../../assets//images/download.svg);
    background-repeat: no-repeat;
    background-position: 20% center;
    background-size: 20%;
    padding-left: 32px;
}

.secondary:hover,
.pdf:hover {
    background: var(--white);
    color: var(--secondary);
    border: 1px solid var(--secondary);
}

.pdf:hover {
    background-image: url(../../assets/images/downloadHover.svg);
    background-repeat: no-repeat;
    background-position: 20% center;
    background-size: 20%;
}

.cancel { 
    background: var(--stroke);
    color: var(--black);
}

.cancel:hover {
    color: var(--error);
    border: 1px solid var(--error);
}

.delete {
    color: var(--error);
    background: rgba(246, 81, 100, 0.25);
}

.delete:hover {
    color: var(--white);
    background: var(--error);
}

.sectionBtn {
    background: var(--secondary);
    color: var(--white);
    position: relative;
    width: 100%;
    padding: 24px 30px;
    border-radius: 10px;
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 51px;
    text-align-last: left;
} 