.main {
    width: 100%;
    margin-top: 30px;
}

.checkboxWrapper { 
    width: 48vw;
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-bottom: 16px;
}

.checkboxContainer {
    display: flex;
    width: 33.3%;
    align-items: center;
    margin-bottom: 24px;
}

.checkbox {
    height: 14px;
    width: 14px;
    margin-right: 16px;
    cursor: pointer;
}

.color1,
.color2,
.color3,
.color4,
.color5 {
    width: 48px;
    height: 24px;
    border-radius: 5px;
    margin-right: 10px;
}

.color1 {
    background: var(--green);
}

.color2 {
    background: var(--primary);
}

.color3 {
    background: var(--accent);
}

.color4 {
    background: var(--error);
}

.color5 {
    background: var(--purple);
}

.checkboxText {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    margin-right: 36px;
}

.grid {
    width: 92%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 3fr 3fr 1fr 2.2fr 2.2fr 3fr 1fr;
    align-items: center;
    justify-items: center;
    grid-row-gap: 20px;
    grid-column-gap: 30px;
    margin-bottom: 20px;
}

.header {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: var(--black);
}

.title {
    color: var(--primary);
    cursor: pointer;
    text-align: center;
}

.delete {
    cursor: pointer;
}

.inputTime {
    width: 130px !important;
    height: 40px;
    border: 1px solid var(--stroke);
    border-radius: 10px;
    outline: none;
    text-indent: 12px;
    color: var(--black); 
    background-image: url(../../../assets/images/clock.png);
    background-repeat: no-repeat;
    background-position: 94% center;
}

.inputTime::placeholder {
    color: var(--gray);
}