.arrow { 
    position: absolute;
    right: 44px;
    top: 44px;
}

.section {
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 60px 0 20px 0;
}

.flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;
}

.flex > * {
    margin-bottom: 40px;
}

.inputDate {
    height: 40px;
    border: 1px solid var(--stroke);
    border-radius: 10px;
    outline: none;
    text-indent: 12px;
    width: 100%;
    color: var(--black); 
    background-image: url(../../assets/images/calendar.png);
    background-repeat: no-repeat;
    background-position: 94% center;
    padding-right: 12px;
}

.inputDate::placeholder {
    color: var(--gray);
}

.flexTab {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 60px;
}

.tab {
    border: 1px solid transparent;
    border-radius: 0;
    padding: 0;
    padding-bottom: 10px;
    margin: 0 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--gray);
    background: var(--white);
    cursor: pointer;
    transition: all 0.3s ease-out 0.1s;
}

.tab:hover {
    color: var(--primary);
}

.hr {
    border: none;
    height: 4px;
    background-color: var(--stroke);
} 

.footer {
    max-width: 800px;
    display: grid;
    place-items: center;
    margin: 0 auto;
    grid-template-columns: 1.2fr 1fr 1fr 1fr;
    row-gap: 20px;
    margin-bottom: 40px;
}

.footer2 {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 0 auto;
    margin-bottom: 40px;
}

.footer2 {
    width: 870px;
}

.footerText {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: var(--black);
    margin-right: 20px;
}

.footerTitle {
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: var(--secondary);
    margin-bottom: 30px;
}

.dataTag {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: var(--secondary);
}

.grid {
    display: grid;
    place-items: center;
}

.required {
    border: 1px solid var(--error);
}

.mb {
    margin-bottom: 20px;
}

.dateContainer {
    position: relative;
    width: calc(25% - 22.5px);
    display: inline-block;
}

.datePlaceholder {
    position: absolute;
    top: -7px;
    left: 12px;
    padding: 0 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: var(--secondary);
    background: var(--white);
    z-index: 2;
}